import { IMedia } from '@alterpage/gatsby-source-alterpress';

import { IUserTokenData } from './user.model';
import { IFileBase64 } from './file-base-64.model';
import { ILocationAddress } from './address.model';

export type TClientPermission =
    | 'Client'
    | 'Demo'
    | 'SuperUser'
    | 'can-projects'
    | 'can-anonymize'
    | 'can-advertisements'
    | 'can-shop'
    | 'can-users'
    | 'can-teams'
    | 'can-questions'
    | 'can-settings'
    | 'can-contact';

export interface IClient {
    userId: number;
    clientId: number;
    memberId: number;
    advertisements: number;
    phone: string;
    email: string;
    taxNumber: string;
    firstName: string;
    lastName: string;
    hasConfirmedEmail: boolean;
    username: string;
    token: IUserTokenData;
    status: EClientStatus;
    name: string;
    media: IMedia[];
    address: ILocationAddress;
    credits: number;
    permissions: TClientPermission[];
    packages: IClientPackage[];
}

export interface IClientPackage {
    name: string;
    start: number;
    finish: number;
    creditsAvailable: number;
    creditsPackage: number;
    businessMeaning: number;
}

export interface IClientRegisterMutation {
    email: string;
    password: string;
    taxNumber: string;
    companyName: string;
    address: ILocationAddress;
}

export interface IClientUpdateMutation {
    companyName?: string;
    logo?: IFileBase64 | '';
    address: Partial<ILocationAddress>;
}

export enum EClientStatus {
    notVerified,
    verified,
}

export interface IClientContactCandidatesMutation {
    advertisementId?: string | number | null;
    candidatesIds: string[] | number[];
    email: {
        subject: string;
        content: string;
        attachments: IFileBase64[];
    };
    legalConsent: {
        content: string;
    };
    sms: {
        content: string;
    };
}
